
const _ = require('underscore');
const BCImageLoader = require('bc-image-loader');
const jQuery = require('jquery');
const bcGalleryJQuery = jQuery.noConflict(true);
const jQueryOriginal = jQuery || bcGalleryJQuery;
const { AnalyticsEngine } = require('./analytics');
const { setupPlayers } = require('./perform');
const { VideoDownloads } = require('./videoDownloads');
const { isUniversalAnalyticsTrackingId } = require('../../../../shared/lib/utils');

global.bcGallery = global.bcGallery || {};
global.bcGallery.$ = global.bcGallery.jQuery = bcGalleryJQuery;
global.jQuery = global.$ = jQueryOriginal;

window.$ = jQueryOriginal;
require('chosen-js');
require('bootstrap');

// register Handlebars helpers
require('../../../../shared/lib/handlebarsHelpers')();

function initGlobals () {
  // All initialization from former sharedJS
  const assembledData = global.bcGallery.filteredAssemblerData;

  global.BCLS = global.BCLS || {};
  global.bc_gallery = global.bc_gallery || {};
  global.bcGallery = global.bcGallery || {};
  global.BCLS.imageTranscoder = assembledData.imageTranscoder;

  global.bcGallery.imageLoader = new BCImageLoader({
    transcoder: global.BCLS.imageTranscoder,
  });

  global.baseUrl = assembledData.baseUrl;
  global.playerBackground = assembledData.playerBackground;
  global.isPreview = assembledData.isPreview ? true : false;
  global.locale = assembledData.locale;
  global.site = assembledData.site;
  global.query = assembledData.query;

  global.bc_gallery.player = {
    account: assembledData.player.account,
    id: assembledData.player.id,
  };
  global.bcGallery.mute = assembledData.mute;

  global.bcGallery.templatePath = assembledData.templatePath;
  global.bcGallery.getTemplatePath = (url) => assembledData.templatePath + url;

  if (assembledData.site.addons) {
    const googleAnalyticsTrackingId = assembledData?.site?.addons?.ga?.trackingId || '';
    global.addons = {
      eloquaId: assembledData.site.addons.eloqua ? assembledData.site.addons.eloqua.siteId : null,
      marketoId: assembledData.site.addons.marketo ? assembledData.site.addons.marketo.accountId : null,
      gaId: googleAnalyticsTrackingId,
      /**
      * TODO: Remove isGoogleAnalyticsLegacy property after the Google Analytics G4 is completed
      */
      isGoogleAnalyticsLegacy: isUniversalAnalyticsTrackingId(googleAnalyticsTrackingId),
    };
  }

  if (assembledData.videos) {
    global.BCLS.videos = {
      nextPage: assembledData.videos.nextPage,
      prevPage: assembledData.videos.prevPage,
    };
  }
  if (assembledData.singleVideo) {
    global.currentVideo = assembledData.singleVideo;
    global.singleVideo = assembledData.singleVideo;
  } else if (assembledData.video) {
    global.currentVideo = {
      id: assembledData.video.id,
      displayName: assembledData.video.name,
      referenceId: assembledData.video.referenceId,
      duration: assembledData.video.length,
    };
  }

  if (assembledData.category) {
    global.category = {
      slug: assembledData.category.slug,
      name: assembledData.category.name,
      id: assembledData.category.id,
      children: assembledData.category.children ? assembledData.category.children.map((child) => {
        return {
          slug: child.slug,
          name: child.name,
        };
      }) : [],
    };
  }

  global.categories = _.map(assembledData.categories, sanitizeCategory);

  global.translations = assembledData.translations;

  if (assembledData.samlAttributes) {
    global.samlAttributes = assembledData.samlAttributes;
  }

  global.constants = assembledData.sharedConstants;
  // from templateScript handlebars template

  global.bcGallery.videoDownloads = new VideoDownloads({
    accountId: assembledData.account.bcAccountId,
    policyKey: assembledData.site.policyKey,
    downloadOptions: assembledData.site.videoDownload,
    downloadProxyUrl: assembledData.site.downloadProxyUrl,
    siteSlug: assembledData.site.slug,
  });

  // Dependency Injection
  let analyticsOptions = {};
  // analytics should be disabled in preview mode
  if (!global.isPreview && global.addons) { //only try reading data if analytics is turned on by user in studio
    analyticsOptions = {
      eloquaId: global.addons.eloquaId,
      marketoId: global.addons.marketoId,
      gaId: global.addons.gaId,
      /**
      * TODO: Remove isGoogleAnalyticsLegacy property after the Google Analytics G4 is completed
      */
      isGoogleAnalyticsLegacy: global.addons.isGoogleAnalyticsLegacy && global.ga,
    };
  }

  //export the engine and initialize the enabled analytics systems and track the current page view
  global.BCLS.analyticsEngine = new AnalyticsEngine(analyticsOptions);

  //Try to instantiate the player
  try {
    setupPlayers();
  } catch (err) {
    //If there's an error, don't block the rest of the JS from running, rethrow the error on the next tick
    setTimeout(function () {
      throw err;
    });
  }
}

function sanitizeCategory (c) {
  if (c.children) {
    c.children = _.map(c.children, sanitizeCategory);
  }
  // Only return fields that are necessary
  c = _.pick(c, 'id', 'slug', 'name', 'parent', 'children', 'baseUrl', 'url', 'site', 'isHeader');
  return c;
}

module.exports = {
  initGlobals,
};

