const _ = require('underscore');

function pad (data, size, padding) {
  data = '' + data;
  while (data.length < size) {
    data = padding + data;
  }
  return data;
}

// Sanitize a string to be passed into a regular expression without side effects
function escapeForRegExp (value) {
  if (_.isUndefined(value)) {
    return '';
  }
  return value.toString().replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

function trim (value, chars) {
  chars = escapeForRegExp(chars);
  return value.replace(new RegExp('^(' + chars + ')+|(' + chars + ')+$', 'g'), '').toLowerCase();
}

function toSlug (value) {
  value = value || '';
  return value.trim().replace(/[%\\\s\/?#\[\]@!\$&\'\(\)\*\+,;="]{1,}/g, '-').replace(/^-+|-+$/g, '').toLowerCase();
}

function getQueryParams (url) {
  if (!url) {
    return false;
  }
  const query = url.split('?')[1];
  return _.chain(query.split('&'))
    .map(function (params) {
      const p = params.split('=');
      return [p[0], decodeURIComponent(p[1])];
    })
    .object()
    .value();
}

function toQueryString (qsObj) {
  const strings = Object.entries(qsObj)
    .filter(function (entry) { return entry[1] != null; })
    .map(function (entry) { return entry.map(encodeURIComponent).join('='); });
  return strings.length ? '?' + strings.join('&') : '';
}

function isUniversalAnalyticsTrackingId (googleTrackingCode) {
  return googleTrackingCode ? googleTrackingCode.startsWith('UA-') : null;
}

module.exports = {
  pad,
  escapeForRegExp,
  trim,
  toSlug,
  toQueryString,
  getQueryParams,
  isUniversalAnalyticsTrackingId,
};
